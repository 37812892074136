import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import MainSection100 from '../components/Layout/main-section-100';
import MainSection50 from '../components/Layout/main-section-50';
import NewLayout from '../components/new_layout';
import Seo from '../components/seo';
import ItemGroup from '../components/v2023/UI/Item/item-group';
import LinkButton from '../components/v2023/UI/LinkButton/link-button';
import FloatingBar from '../components/v2024/FloatingBar/floating-bar';
import { Tile } from '../components/v2024/tile/tile';
import TileList from '../components/v2024/tile/tile-list';
import ModalLogin from '../components/v2024/Login/modal-login';
import MenuModalState from '../components/v2024/Login/modal-login-state';

const FreeTrial = ({ data, pageContext }) => {
	const page = data.page;
	const lang = pageContext.langKey

	const loginModalState = MenuModalState()

	return (
		<NewLayout noHeader thinFooter pageContext={pageContext}>
			<Seo
				description={page.seo.meta_description}
				title={page.seo.title}
				image={page.seo.image_2.localFile.publicUrl}
			/>

			{/* HERO */}
			<MainSection50
				sectionType="color"
				sectionColor="purple-dark"
				sectionSize= 'lg'
				childrenLeft={
					<>
						<GatsbyImage
							alt={page.tecalis_logo.alternativeText}
							image={getImage(page.tecalis_logo.localFile)}
						/>
						<h1 className="merriweather">{page.hero_title}</h1>
						<div className="container__text">
							<ReactMarkdown children={page.hero_description} rehypePlugins={[rehypeRaw]} />
						</div>
						<div className="button__wrapper">
							<button className='button button--ghost button--xl' onClick={() => loginModalState.openMenu()}>{page.hero_try_button.text}</button>
							{/* The videos still not available, so the button will be commented
							<LinkButton
								button={page.hero_demo_button}
								className="button button--xl button--secondary button--secondary--ghost"
							/> */}
						</div>
					</>
				}
				childrenRight={
					<GatsbyImage image={getImage(page.hero_image.localFile)} alt={page.hero_image.alternativeText} />
				}
			/>

			{/* Sign */}
			<MainSection100>
				<h2>{page.sign_title}</h2>
				<div className="container__text">
					<ReactMarkdown children={page.sign_description} rehypePlugins={[rehypeRaw]} />
				</div>
				<div className="grid-lg-4">
					{page.sign_case_uses.map((case_use, index) => (
						<TileList key={index} list={case_use.description} title={case_use.title} />
					))}
				</div>
			</MainSection100>

			{/* Opportunities */}
			<MainSection100  
				sectionType="color"
				sectionColor="gray"
				sectionId="highlighted_features"
			>
				<h2>{page.highlighted_features_title}</h2>
				<div className="container__text">
					<ReactMarkdown children={page.highlighted_features_description} rehypePlugins={[rehypeRaw]} />
				</div>
				<ItemGroup items={page.highlighted_features_list} hasImage unboxed />
			</MainSection100>

			{/* Logos */}
			<div className="main__section">
				<div className="container container--np">
					<div className="grid-md">
						{page.clients.map((logo, index) => {
							return <GatsbyImage key={index} image={getImage(logo.image.localFile)} alt={logo.alt} />;
						})}
					</div>
				</div>
			</div>

			{/* CTA */}
			<MainSection100>
				<Tile title={page.cta.name} body={page.cta.description} button={{text: page.cta.button}} buttonAction={() => loginModalState.openMenu()} bigTitle/>
			</MainSection100>

			{/* Floating Bar */}
			<FloatingBar
				text={page.floating_bar.text}
				button={
					<LinkButton className='button button--secondary button--lg' button={page.floating_bar.button} />
				}
				idItemToShow='highlighted_features'
			/>

			<ModalLogin plan={page.register_form} lang={lang} selectedPlan="free" state={loginModalState} />
		</NewLayout>
	);
};

export default FreeTrial;

export const freeTrialQuery = graphql`
	query ($id: String!, $langKey: String!)  {
		page: strapiFreeTrial(locale: {eq: $langKey}, id: {eq: $id}){
			seo {
				title
				meta_description
				image_2 {
					localFile {
						publicURL
					}
				}
			}
			tecalis_logo {
				name
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(width: 147, placeholder: BLURRED, formats: [WEBP], breakpoints: [147])
					}
				}
			}
			hero_title
			hero_description
			hero_try_button {
				text
				url
				blank
			}
			hero_demo_button {
				text
				url
				blank
			}
			hero_image {
				name
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(width: 656, placeholder: BLURRED, formats: [WEBP])
					}
				}
			}
			sign_title
			sign_description
			sign_case_uses {
				title
				description
			}
			highlighted_features_title
			highlighted_features_description
			highlighted_features_list {
				id
				order
				title
				description
				icon {
					name
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 608, placeholder: BLURRED, formats: [WEBP])
						}
					}
				}
			}
			clients {
				alt
				image {
					name
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 120, placeholder: BLURRED, formats: [WEBP], breakpoints: [120])
						}
					}
				}
			}
			cta {
				name
				description
				button
				url
				blank
			}
			floating_bar {
				text
				button {
					text
					url
					blank
				}
			}
            register_form {
                form_email
                or_sign_up
                login_text
                form_name
                form_tc_description
                form_repassword
                form_tc_description
                form_password
                title
                description
                button_google
                button_email
                form_lastname
                signup_text
                button_signup
                button_login
                remember_me
                forgot_password
                title_login
                description_login
                form_tc_info
            }
		}
	}
`;
